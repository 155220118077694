import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import App from '../../components/app';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uses",
        "aria-label": "uses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uses`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Full disclosure, all amazon links are affiliate links. Thank you! 💚`}</p>
    </blockquote>
    <h2 {...{
      "id": "desktop-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#desktop-apps",
        "aria-label": "desktop apps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Desktop Apps`}</h2>
    <App name="Brave Browser" icon={require('./icons/brave.png')} href="https://brave.com" mdxType="App" />
    <App name="Visual Studio Code" icon={require('./icons/vscode.png')} href="https://code.visualstudio.com" mdxType="App" />
    <App name="Alfred 3" icon={require('./icons/alfred.png')} href="https://www.alfredapp.com" mdxType="App" />
    <App name="Spectacle" icon={require('./icons/spectacle.png')} href="https://www.spectacleapp.com/" mdxType="App" />
    <App name="aText" icon={require('./icons/atext.png')} href="https://trankynam.com/atext/" mdxType="App" />
    <App name="Fantastical 2" icon={require('./icons/fantastical.png')} href="https://flexibits.com/fantastical" mdxType="App" />
    <App name="Hyper" icon={require('./icons/hyper.png')} href="https://hyper.is/" mdxType="App" />
    <App name="Slack" icon={require('./icons/slack.png')} href="https://slack.com/" mdxType="App" />
    <App name="Tower" icon={require('./icons/tower.png')} href="https://www.git-tower.com/mac" mdxType="App" />
    <App name="Todoist" icon={require('./icons/todoist.png')} href="https://todoist.com/r/dustin_davis_vhlbue" mdxType="App" />
    <App name="VLC" icon={require('./icons/vlc.png')} href="https://www.videolan.org/vlc/" mdxType="App" />
    <App name="Evernote" icon={require('./icons/evernote.png')} href="https://evernote.com/" mdxType="App" />
    <App name="Camtasia 3" icon={require('./icons/camtasia.png')} href="https://www.techsmith.com/video-editor.html" mdxType="App" />
    <App name="Adobe Photoshop" icon={require('./icons/photoshop.png')} href="https://www.adobe.com/products/photoshop.html" mdxType="App" />
    <App name="Adobe Illustrator" icon={require('./icons/ai.png')} href="https://www.adobe.com/products/illustrator.html" mdxType="App" />
    <App name="Bitwarden" icon={require('./icons/bitwarden.png')} href="https://bitwarden.com/" mdxType="App" />
    <App name="Calibre" icon={require('./icons/calibre.png')} href="https://calibre-ebook.com/" mdxType="App" />
    <App name="Day One" icon={require('./icons/dayone.png')} href="https://dayoneapp.com/" mdxType="App" />
    <App name="Discord" icon={require('./icons/discord.png')} href="https://discord.com/" mdxType="App" />
    <App name="Flycut" icon={require('./icons/flycut.png')} href="https://apps.apple.com/us/app/flycut-clipboard-manager/id442160987?mt=12" mdxType="App" />
    <App name="Grammarly" icon={require('./icons/grammarly.png')} href="https://app.grammarly.com/" mdxType="App" />
    <App name="LightPaper" icon={require('./icons/lightpaper.png')} href="https://getlightpaper.com/" mdxType="App" />
    <App name="Plex" icon={require('./icons/plex.png')} href="https://plex.tv" mdxType="App" />
    <App name="Snap Camera" icon={require('./icons/snapcamera.png')} href="https://snapcamera.snapchat.com/" mdxType="App" />
    <App name="Telegram" icon={require('./icons/telegram.png')} href="https://telegram.org/" mdxType="App" />
    <App name="ToothFairy" icon={require('./icons/toothfairy.png')} href="https://apps.apple.com/us/app/toothfairy/id1191449274?mt=12" mdxType="App" />
    <div style={{
      "clear": "both"
    }} />
    <h2 {...{
      "id": "browser-extensions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#browser-extensions",
        "aria-label": "browser extensions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Browser Extensions`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/bitwarden-free-password-m/nngceckbapebfimnlniiiahkandclblb"
        }}>{`Bitwarden`}</a>{` -
self-hosted password manager`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/tab-snooze/pdiebiamhaleloakpcgmpnenggpjbcbm"
        }}>{`Tab Snooze`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/tab-to-windowpopup-keyboa/adbkphmimfcaeonicpmamfddbbnphikh"
        }}>{`Tab to Window/Popup - Keyboard Shortcut`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/chase-amazon-search/iiaekebldphlpkgjhafcgfeocmgidmpj"
        }}>{`Chase Amazon Search`}</a>{` -
I wrote this one myself`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/colorzilla/bhlhnicpbhignbdhedgjhgdocnmhomnp"
        }}>{`ColorZilla`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/grammarly-for-chrome/kbfnbcaeplbcioakkpcpgfkobkghlhen"
        }}>{`Grammarly`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/video-speed/fnjmlegmhldjolpmcfjhooibnchlafgg"
        }}>{`Video Speed`}</a></li>
    </ul>
    <h2 {...{
      "id": "clis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#clis",
        "aria-label": "clis permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CLIs`}</h2>
    <p>{`I have far more packages installed, but these are some of my more commonly used
CLI tools.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linux.die.net/man/1/ack"
        }}>{`ack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ffmpeg.org/"
        }}>{`ffmpeg`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://git-scm.com/"
        }}>{`git`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brew.sh/"
        }}>{`Homebrew`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://imagemagick.org/index.php"
        }}>{`imagemagick`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sandreas/m4b-tool"
        }}>{`m4b-tool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mongodb.com/"
        }}>{`mongodb`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/"
        }}>{`node`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pyenv/pyenv"
        }}>{`pyenv`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.python.org/"
        }}>{`python3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://redis.io/"
        }}>{`redis`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://plasmasturm.org/code/rename/"
        }}>{`rename`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/BurntSushi/ripgrep"
        }}>{`ripgrep`}</a>{` (a better grep)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ruby-lang.org/en/"
        }}>{`ruby`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sqlite.org/index.html"
        }}>{`sqlite`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.google.com/speed/webp/"
        }}>{`web`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gnu.org/software/wget/"
        }}>{`wget`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://yarnpkg.com/"
        }}>{`yarn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ytdl-org.github.io/youtube-dl/"
        }}>{`youtube-dl`}</a></li>
    </ul>
    <h2 {...{
      "id": "my-office",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#my-office",
        "aria-label": "my office permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My Office`}</h2>
    <h3 {...{
      "id": "desk-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#desk-setup",
        "aria-label": "desk setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Desk Setup`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apple.com/macbook-pro-16/"
        }}>{`MacBook Pro (15-inch, 2019)`}</a></li>
      <li parentName="ul">{`My desk is customized - build from pottery barn furniture and IKEA faux
butcher block.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2Rb0TXA"
        }}>{`OWC USB-C Dock`}</a>{` - dock I connect to all my
peripherals.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3m9H5C3"
        }}>{`Dell UltraSharp 27 Inch 4K Monitor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2ZkSPb2"
        }}>{`Matias Bluetooth Wireless Keyboard with Numeric Keypad and 4-Device Sync`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3bKciXD"
        }}>{`Magic Mouse 2`}</a>{` - I have two of these. It is handy
for travel and to swap out when one is charging. (The charging port was put in
the worst spot!)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2ZEnPmT"
        }}>{`ScanSnap Scanner`}</a>{` - Awesomely pairs with Evernote to
help you go paperless.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3bGaM9f"
        }}>{`Laptop Stand`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/32gI2Rn"
        }}>{`Phone Stand`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2FjcqkU"
        }}>{`Echo Show 5`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3m95jwv"
        }}>{`Logitech HD Pro Webcam C920`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3m6NbTN"
        }}>{`Anker Soundcore Life Q20 Hybrid Active Noise Cancelling Headphones`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/32fDlHk"
        }}>{`Canon Color Laser Printer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/35nBSk3"
        }}>{`Canon All-In-One Printer`}</a></li>
    </ul>
    <h2 {...{
      "id": "on-the-go",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#on-the-go",
        "aria-label": "on the go permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On the Go`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3bHPnMC"
        }}>{`OGIO Metro Street Computer Laptop Backpack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3m5fySr"
        }}>{`Anker 20100mAh Portable Charger`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3m4jxyx"
        }}>{`Fuse Reel MacBook Charger Organizer`}</a>{` 💯`}</li>
    </ul>
    <h2 {...{
      "id": "home-stuff",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-stuff",
        "aria-label": "home stuff permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Stuff`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/33f1wEZ"
        }}>{`Synology RT2600ac Router`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3bHbyTj"
        }}>{`Synology 4 bay NAS DiskStation DS920+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3igbQmF"
        }}>{`Western Digital 4TB WD Red NAS Internal Hard Drive - 5400 RPM Class`}</a>{` -
for the NAS`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      